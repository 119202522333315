import * as React from 'react'
import DefaultLayout from 'layouts/Default'
import { graphql, useStaticQuery } from 'gatsby'
import HeroWithBg from 'components/common/HeroWithBg'
import Flag from 'components/common/Flag'

const TechForYourAgencyPg = () => {
  const { sanityTechForYourAgencyPg: pg } = useStaticQuery(query)

  return (
    <DefaultLayout {...pg.seo}>
      <HeroWithBg {...pg.hero} />
      {pg.items?.map((item, key) => <Flag {...item} key={key} />)}
    </DefaultLayout>
  )
}

export default TechForYourAgencyPg

const query = graphql`query TechForYourAgencyPg {
  sanityTechForYourAgencyPg {
    hero { ...hero }
    items {
      title
      _rawDescription
      image { asset { gatsbyImageData(placeholder: NONE, width: 200, layout: CONSTRAINED) } }
    }
    seo {
      title
      description
    }
  }
}`
