import * as React from 'react'
import * as css from './Flag.module.css'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SanityBlock from 'components/common/SanityBlock'
import Cta from 'components/common/CTA'

const Flag = ({ title, _rawDescription, cta, image }) => (
  <section className={`${css.flag} container padding`}>
      <GatsbyImage
        image={getImage(image.asset)}
        alt={title}
        objectFit="contain"
      />

      <div className="richtext">
        <h3 className="h2">{title}</h3>
        <SanityBlock body={_rawDescription} />
        {cta && <div><Cta {...cta} /></div>}
      </div>
  </section>
)

export default Flag
